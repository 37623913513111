<template>
  <d-container fluid>
    <d-row>
      <main-sidebar :items="sidebarItems"/>
      <d-col class="main-content offset-lg-2 offset-md-3 p-0" tag="main" lg="10" md="9" sm="12">
        <main-navbar/>
        <d-container fluid>
          <pageTitle :title="`Overzicht`" :subtitle="`Gebruiker`"/>
          <d-row>
            <vCard :col="12" :header="`test`">
              <input type="file" @change="onFileChange">
            </vCard>
          </d-row>
        </d-container>
      </d-col>
    </d-row>
  </d-container>
</template>

<script>
// Main layout components
import MainNavbar from '../../components/layout/MainNavbar/MainNavbar.vue';
import MainSidebar from '../../components/layout/MainSidebar/MainSidebar.vue';

export default {
  name: 'notifications',
  components: {
    MainNavbar,
    MainSidebar
  },
  menu: [
    {
      title: 'Home',
      to: {
        route: 'home'
      },
      htmlBefore: '<i class="material-icons">home</i>'
    },
    {
      title: 'Profiel',
      to: {
        route: 'profile'
      },
      htmlBefore: '<i class="material-icons">person</i>'
    }
  ],
  data() {
    return {
      sidebarItems: [],
      image: null
    };
  },
  methods: {
    goToModule(router) {
      this.$goToModule(router)
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;

      console.log('file uploadde');
      console.log(files[0])
      // this.$api.user.uploadProfilePicture(3, files)
      // this.createImage(files[0]);
    },

  }
}
</script>
